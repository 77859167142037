'use client'

import React, { ComponentType, ReactElement, ReactNode } from 'react'
import Link from '@/components/router/Link'
import { Heading } from '@/components/ui-other/text/Heading'
import { ChevronRight, MapPin, PlusCircle } from 'react-feather'
import Image, { maxScreenSize } from '@/components/ui-other/basic/Image'
import checkLogo from 'shared/imageHelpers'
import Button from '@/components/ui-other/basic/Button'
import { isTabletScreen } from 'shared/domHelpers'

export type Breadcrumb = {
  text?: string
  path?: string
}

type ContentHeaderProps = {
  title: string
  subtitle?: string | null
  subtitleUrl?: string | null
  logo?: string
  addButton?: string
  maps?: string | null
}

export const Breadcrumbs = ({
  breadcrumbs
}: {
  breadcrumbs?: Breadcrumb[]
}): ReactElement | null => {
  if (!breadcrumbs || breadcrumbs.length === 0) return null
  const output = breadcrumbs
    .map<ReactNode>(
      (b, i) =>
        b.text &&
        (b.path ? (
          <Link
            key={i}
            href={b.path}
            className="breadcrumbs truncate text-sm text-gray-400"
          >
            {b.text}
          </Link>
        ) : (
          <span
            key={i}
            className="breadcrumbs text-sm truncate text-gray-400 cursor-default"
          >
            {b.text}
          </span>
        ))
    )
    .reduce((prev, curr, i) => [
      prev,
      <ChevronRight
        key={`chevron-${i}`}
        className="inline h-4 text-gray-600"
      />,
      curr
    ])
  // Works, but TS is not happy
  // @ts-ignore
  return output || null
}

const ContentHeader: ComponentType<ContentHeaderProps> = ({
  title,
  subtitle,
  subtitleUrl,
  logo,
  addButton,
  maps
}) => {
  const isTablet = isTabletScreen()
  return (
    <div className="flex space-x-4 mb-4 items-center overflow-hidden">
      {logo && (
        <Image
          className="w-16 h-8"
          src={checkLogo(logo, 'club')}
          alt={'Logo'}
          objectFit="contain"
          srcSet={[[100, maxScreenSize]]}
        />
      )}
      <div className="w-full pr-4 overflow-hidden">
        <div className="flex items-center space-x-4">
          <Heading level={1} size="xl" className=" truncate">
            {title}
          </Heading>
          {maps && (
            <a
              href={encodeURI(
                `https://www.google.com/maps/search/?api=1&query=${maps}`
              )}
              target="_blank"
              rel="noreferrer"
              className="ml-4"
            >
              <Button iconOnly>
                <MapPin /> {!isTablet && <span>Routenplaner</span>}
              </Button>
            </a>
          )}
        </div>
        {subtitle && subtitleUrl && (
          <Link href={subtitleUrl} className="text-sm text-gray-500">
            {subtitle}
          </Link>
        )}
        {subtitle && !subtitleUrl && (
          <div className="text-sm text-gray-500">{subtitle}</div>
        )}
      </div>
      {addButton && (
        <Link href="" className="text-gray-500">
          <PlusCircle size="1.25em" />
        </Link>
      )}
    </div>
  )
}

export default ContentHeader
