'use client'

import React, { ReactElement, ReactNode } from 'react'
import { UiComponent } from '@/components/ui/types'

export type ButtonColors =
  | 'none'
  | 'link'
  | 'primary'
  | 'transparent'
  | 'primary-outline'
  | 'white'
  | 'gray'
  | 'underline'
  | 'hbnet'

export type ButtonProps = {
  children: ReactNode
  onClick?: () => void
  className?: string
  color?: ButtonColors
  disabled?: boolean
  iconOnly?: boolean
  type?: 'button' | 'submit' | 'reset'
  tooltip?: string
  link?: boolean
  small?: boolean
  onMouseEnter?: () => void
  onMouseLeave?: () => void
} & UiComponent

const Button = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  className = '',
  color = 'primary',
  disabled = false,
  iconOnly = false,
  type = 'button',
  tooltip = '',
  link = false,
  small = false
}: ButtonProps): ReactElement => {
  return (
    <button
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      data-tooltip-content={tooltip || ''}
      data-tooltip-id="layout-tooltip"
      className={`
        ${link ? 'btn-link' : 'btn'}
        ${color && color !== 'none' && !link ? 'btn-' + color : ''}
        ${iconOnly ? 'btn-icon-only' : ''}
        ${small ? 'btn-sm' : ''}
        ${className}
        space-x-1
      `}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  )
}

export default Button
